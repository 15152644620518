import Footer from "../components/Footer";

const AppSupport = () => {
    return (<>
            <section id="appSupport" className="support">
                <div className="support_container">
                    <h1 dir="ltr">
                        <center>Hubble Network Demo App Support</center>
                    </h1>
                    <h2 dir="ltr">
                        Welcome to the Hubble Network Demo Support Page!
                    </h2>
                    <p dir="ltr">
                        The Hubble Demo App allows users to test the capabilities of Hubble Network's groundbreaking
                        off-the-shelf bluetooth device direct-to-satellite satellite technology. With this app, users
                        can
                        seamlessly connect demo devices, and monitor transmissions.
                        Whether you're evaluating Hubble's network for asset tracking, remote sensing, or other IoT use
                        cases,
                        this app helps ensure a smooth, efficient technology validation demonstration before
                        transitioning to
                        full-scale deployment.
                    </p>
                    <center className="support_link">
                        <h2> For support inquiries, please contact us at:</h2>
                        <p>
                            <a href="mailto:support@hubblenetwork.com">support@hubblenetwork.com</a>
                        </p>
                    </center>

                    <h2>Frequently asked questions:</h2>
                    <ul>
                        <li dir="ltr">
                            <h3>How do I create an account?</h3>
                            <p dir="ltr">You can create an account by opening the app and tapping on "Sign Up". We will
                                send a verification link to the email address you provided. Open the link to verify your
                                account. Once verified, you can sign in using this email address as your username.</p>
                        </li>

                        <li dir="ltr">
                            <h3>Why do you request Bluetooth and location permissions?</h3>
                            <p dir="ltr">We use Bluetooth to locate and communicate with our device.
                                To send data to the satellite, you need to select a transmission time based on the
                                available
                                windows
                                we provide, which are determined by your location.
                                These "windows" are time frames when satellites are in range and ready to receive
                                data.</p>
                        </li>

                        <li dir="ltr">
                            <h3>Why don't I see my device on the scan page?</h3>
                            <p>Here are a few things you can check:</p>
                                <ol>
                                    <li>Ensure the device is nearby, turned on, and has a charged battery.</li>
                                    <li>Tap "Scan" to start scanning for devices.</li>
                                    <li>Toggle Bluetooth on and off, and check the app's Bluetooth permissions in your
                                        phone's settings.
                                    </li>
                                    <li>Restart your phone and try scanning again.</li>
                                    <li>If none of these steps work, contact our support team for assistance.</li>
                                </ol>
                        </li>

                        <li dir="ltr">
                            <h3>Why are there no windows available?</h3>
                            <p dir="ltr">This might occur if there are no high-quality satellite flybys in your location
                                suitable for data transmission. If this persists, please reach out to our team for
                                assistance.
                            </p>
                        </li>

                        <li dir="ltr">
                            <h3>The app isn’t working as expected. What should I do?</h3>
                            <p dir="ltr">Ensure that you have the latest version of the app installed. Restart the app
                                and try again.
                                If the issue continues, feel free to contact our support team for further help.
                            </p>
                        </li>

                    </ul>
                    <div className="goodbye">
                        <center>
                            <h4>Thank you for reaching out!</h4>
                            <p>Feel free to contact with us on our support email. Policy of privacy and terms of service
                                are available for you below.</p>
                        </center>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
        ;
};
export default AppSupport;
