import {useNavigate} from "react-router-dom";

import logo from "../assets/images/navbar/Logo.svg";
import "./Footer.css";
import React from "react";

const Footer = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;
        script.src = "//js.hs-scripts.com/44939329.js";
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="footer">
            <div className="container">
                <a href="#top-banner" style={{display: "contents"}}>
                    <img
                        alt="footer-logo"
                        id="footer-logo"
                        src={logo}
                        onClick={() => navigate("/")}
                    />
                </a>
                <div className="footer-items">
                    <label>Hubble Network © 2024 </label> | <span onClick={() => navigate("/privacy-policy")}>Privacy Policy </span> |{" "}
                    <span onClick={() => navigate("/terms")}>Terms</span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
